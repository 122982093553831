import React, { useState, useEffect } from "react";
import axios from "axios";
import {
    AppBar,
    Box,
    Button,
    IconButton,
    InputBase,
    TextField,
    Toolbar,
    Typography,
    Container,
    Link,
    CircularProgress,
} from "@mui/material";
import { SearchRounded } from "@mui/icons-material";
import styles from "../../results.module.css";
import amazinglogo from "../../img/amazinglogo.png";
import { styled, alpha } from "@mui/material/styles";
import { useLocation } from "react-router-dom";
import TagManager from "react-gtm-module";
import he from "he"; // For decoding HTML entities

// Styled components for the search input
const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": { backgroundColor: alpha(theme.palette.common.white, 0.25) },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: { marginLeft: theme.spacing(3), width: "auto" },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "inherit",
    "& .MuiInputBase-input": {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create("width"),
        width: "100%",
        [theme.breakpoints.up("sm")]: { width: "12ch", "&:focus": { width: "20ch" } },
    },
}));

const AmazingFact = () => {
    const [searchTerm, setSearchTerm] = useState("");
    const [results, setResults] = useState(null);
    const [clientID, setClientID] = useState("");
    const [impressionGUID, setImpressionGUID] = useState("");
    const [rguId, setRGUId] = useState("");
    const [searchID, setSearchID] = useState("");
    const [showSearch, setShowSearch] = useState(false);

    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const clarityId = "ifna6todnx"; // Clarity ID
    const sourceTag = "adssquared_n2s_xmlb_2074_amazingfactorg13"; // SourceTag

    useEffect(() => {
        // If you need to fetch user IP and user agent, you can do it here
        // For now, we're using the navigator's userAgent
        // setUserAgent(navigator.userAgent);
    }, []);

    const normalizeResultSet = (resultSet) => {
        if (!resultSet) return [];
        if (!Array.isArray(resultSet)) {
            return [resultSet];
        }
        return resultSet;
    };

    const normalizeListing = (listing) => {
        if (!listing) return [];
        if (!Array.isArray(listing)) {
            return [listing];
        }
        return listing;
    };

    const fetchResults = async (query) => {
        if (!query) return;
        try {
            const type = params.get("type") || "";
            const country = params.get("country") || "";
            const domain = window.location.hostname;
            const protocol = window.location.protocol;
            const url = `${protocol}//api.${domain}/api/searchfeed?q=${encodeURIComponent(
                query
            )}&type=${encodeURIComponent(type)}&country=${encodeURIComponent(country)}`;

            const response = await axios.get(url);

            console.log("API Response:", response.data);

            // Adjusted to match the new response structure
            setResults(normalizeResultSet(response.data.ResultSet));

            // Set the state with the new details
            setClientID(response.data.ClientID);
            setImpressionGUID(response.data.ImpressionGUID);
            setRGUId(response.data.RGUId);
            setSearchID(response.data.SearchID);
            initializeSelectTier(response.data);
            insertHTMLBeacon(response.data);
        } catch (error) {
            console.error("Error fetching ads:", error);
        }
    };

    const insertHTMLBeacon = (data) => {
        const beaconUrl = `https://search.yahoo.com/beacon/geop/p?s=1197774733&ysid=${data.SearchID}&traffic_source=${sourceTag}`;
        const img = document.createElement("img");
        img.src = beaconUrl;
        img.alt = "";
        img.style.display = "none";
        document.body.appendChild(img);
    };

    useEffect(() => {
        const query = params.get("q");
        if (query) {
            setSearchTerm(query);
            fetchResults(query);
        }

        const subid1 = getClickId();
        if (subid1) {
            TagManager.dataLayer({
                dataLayer: {
                    event: "subid1Event",
                    subid1: subid1,
                },
            });
        }
    }, [location.search]);

    const initializeSelectTier = (data) => {
        console.log("entering initializeSelectTier");
        // Create the script element
        const script = document.createElement("script");

        script.textContent = `
            (function(w,d,t,x,m,l,p){w['XMLPlusSTObject']=m;w[m]=w[m]||function (){(w[m].q=w[m].q||[]).push(arguments)},w[m].l=1*new Date();l=d.createElement(t),p=d.getElementsByTagName(t)[0];l.type="text/javascript";l.async=1;l.defer=1;l.src=x;p.parentNode.insertBefore(l,p)})(window,document,'script','https://s.yimg.com/ds/scripts/selectTier.js','selectTier');selectTier('init',{source_tag:'${sourceTag}',ysid:'${data.SearchID}',cid:'${data.ClientID}',ig:'${data.ImpressionGUID}',select_tier:{clarityId:'${clarityId}',rguid:'${data.RGUId}'},test_mode:false});
        `;

        document.head.appendChild(script);
    };

    const getClickId = () => {
        const params = new URLSearchParams(window.location.search);
        return params.get("subid1");
    };

    const handleSearch = async (event) => {
        event.preventDefault();
        fetchResults(searchTerm);
    };

    const handleKeyPress = (event) => {
        if (event.key === "Enter") {
            fetchResults(searchTerm);
        }
    };

    if (params.get("q") && !results) {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                }}
            >
                <CircularProgress />
            </div>
        );
    }

    return (
        <>
            {results && (
                <AppBar position="static" sx={{ bgcolor: "black", color: "white" }}>
                    <Toolbar>
                        <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
                            <Link href="/" sx={{ color: "white", textDecoration: "none" }}>
                                AmazingFact
                            </Link>
                        </Typography>
                        {!showSearch && (
                            <IconButton
                                sx={{ display: { sm: "none" }, color: "white" }}
                                onClick={() => setShowSearch((prev) => !prev)}
                            >
                                <SearchRounded />
                            </IconButton>
                        )}
                        <Search sx={{ display: { xs: showSearch ? "flex" : "none", sm: "flex" } }}>
                            <SearchIconWrapper>
                                <SearchRounded />
                            </SearchIconWrapper>
                            <StyledInputBase
                                placeholder="Search…"
                                inputProps={{ "aria-label": "search" }}
                                value={searchTerm}
                                onChange={(event) => setSearchTerm(event.target.value)}
                                onKeyPress={handleKeyPress}
                            />
                        </Search>
                    </Toolbar>
                </AppBar>
            )}
            <Container maxWidth="sm">
                {!results && (
                    <Box
                        sx={{
                            minHeight: "80vh",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <h1>
                            <img src={amazinglogo} alt="AmazingFact Logo" />
                        </h1>
                        <TextField
                            label="Search"
                            variant="outlined"
                            sx={{ width: "100%", mb: 2 }}
                            onChange={(event) => setSearchTerm(event.target.value)}
                            onKeyPress={handleKeyPress}
                            value={searchTerm}
                        />
                        <Button
                            variant="contained"
                            startIcon={<SearchRounded />}
                            onClick={handleSearch}
                            sx={{ width: "100%" }}
                        >
                            Search
                        </Button>
                    </Box>
                )}
                <div>
                    <div>
                        {results &&
                            results.map((group, groupIndex) => (
                                <div key={groupIndex}>
                                    {group["@attributes"].id === "searchResults" ? (
                                        <div className={styles["adTag"]}>
                                            Ads Related to <strong>{searchTerm}</strong>
                                        </div>
                                    ) : (
                                        <div className={styles["adTag"]}>
                                            Search Results Related to <strong>{searchTerm}</strong>
                                        </div>
                                    )}

                                    {normalizeListing(group.Listing).map((listing, index) => {
                                        // Construct the click URL
                                        const clickUrl = listing.ClickUrl.startsWith("://")
                                            ? `https${listing.ClickUrl}`
                                            : listing.ClickUrl;

                                        return (
                                            <div
                                                key={index}
                                                className={
                                                    group["@attributes"].id === "searchResults"
                                                        ? `ad ${styles["MuiCard-root"]}`
                                                        : `${styles["MuiCard-root"]}`
                                                }
                                                data-yiid={listing["@attributes"].ImpressionId}
                                                data-appns={listing["@attributes"].appNs}
                                                data-k={listing["@attributes"].k}
                                                data-adultRating={group["@attributes"].adultRating}
                                                data-searchId={group["@attributes"].searchId}
                                            >
                                                <div className={styles["MuiCardContent-root"]}>
                                                    <h2
                                                        className={
                                                            styles["MuiTypography-root"] +
                                                            " " +
                                                            styles["MuiTypography-h5"]
                                                        }
                                                    >
                                                        <a
                                                            href={clickUrl}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            style={{
                                                                display: "inline",
                                                                textDecoration: "none",
                                                                color: "black",
                                                            }}
                                                        >
                                                            {he.decode(
                                                                listing["@attributes"].title || ""
                                                            )}
                                                        </a>
                                                    </h2>

                                                    <div className="ad-body">
                                                        <div className={styles.adContainer}>
                                                            <p
                                                                className={`${styles["MuiTypography-root"]} ${styles["MuiTypography-body2"]} ${styles.siteHost}`}
                                                            >
                                                                {listing["@attributes"].siteHost}
                                                            </p>
                                                        </div>
                                                        {listing["@attributes"].description && (
                                                            <p
                                                                className={
                                                                    styles["MuiTypography-root"] +
                                                                    " " +
                                                                    styles["MuiTypography-body2"]
                                                                }
                                                            >
                                                                {he.decode(
                                                                    listing["@attributes"]
                                                                        .description || ""
                                                                )}
                                                            </p>
                                                        )}
                                                        <a
                                                            href={clickUrl}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            style={{
                                                                display: "inline",
                                                                textDecoration: "none",
                                                                color: "black",
                                                            }}
                                                        >
                                                            <button
                                                                style={{
                                                                    display: "block",
                                                                    width: "100%",
                                                                    marginTop: "16px",
                                                                    backgroundColor: "green",
                                                                    textDecoration: "none",
                                                                    color: "white",
                                                                    borderRadius: "20px",
                                                                    padding: "10px 20px",
                                                                    textTransform: "none",
                                                                    fontSize: "1rem",
                                                                    border: "none",
                                                                    cursor: "pointer",
                                                                }}
                                                                onMouseOver={(e) =>
                                                                    (e.currentTarget.style.backgroundColor =
                                                                        "darkgreen")
                                                                }
                                                                onMouseOut={(e) =>
                                                                    (e.currentTarget.style.backgroundColor =
                                                                        "green")
                                                                }
                                                            >
                                                                See It
                                                            </button>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            ))}
                    </div>
                </div>
            </Container>
        </>
    );
};

export default AmazingFact;