import * as React from 'react';

import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import { blue } from '@mui/material/colors';
import Search from "./Search/Search";
import {ManageSearch} from "@mui/icons-material";
import bing from "../img/bing.png"
import yahoo from "../img/yahoo.png"


const searchEngines = ['Bing', 'Yahoo'];

function SearchEngineDialog(props) {
    const { onClose, selectedValue, open } = props;

    const handleClose = () => {
        onClose(selectedValue);
    };

    const handleListItemClick = (value) => {
        onClose(value);
    };

    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>Set Search Engine</DialogTitle>
            <List sx={{ pt: 0 }}>
                {searchEngines.map((engine) => (
                    <ListItem button onClick={() => handleListItemClick(engine)} key={engine}>
                        <ListItemAvatar>
                            <Avatar >
                            <img src={engine == "Bing" ?bing:yahoo}/>

                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={engine} />
                    </ListItem>
                ))}

            </List>
        </Dialog>
    );
}

export default SearchEngineDialog