import React, {useState, useEffect, useRef} from "react";
import {
    Box,

    CssBaseline,
    Typography, InputBase, Paper, Grid, IconButton, LinearProgress, CircularProgress, Button
} from "@mui/material";
import {SearchRounded} from "@mui/icons-material";
import {useSearchParams} from "react-router-dom";
import Dialog from "../SearchEngineDialog";
import SearchEngineDialog from "../SearchEngineDialog";
import bing from "../../img/bing.png"
import yahoo from "../../img/yahoo.png"

import axios from 'axios';
import PrivacyModal from "../PrivacyModal";
import TermsModal from "../TermsModal";
import ContactModal from "../ContactModal";
import SearchResults from "./Results";


function Search() {


    const [keyword, setKeyword] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    const [searchEngine, setSearchEngine] = useState(null);
    const [selectedValue, setSelectedValue] = React.useState(null);
    const [open, setOpen] = React.useState(false);

    const [extensionInfo, setExtensionInfo] = useState(null);
    const [logo, setLogo] = useState(null);


    const [searchParams, setSearchParams] = useSearchParams();

    const [privacyModalOpen, setPrivacyModalOpen] = useState(false);

    const handlePrivacyModalClose = () => {
        setPrivacyModalOpen(false);
    };

    const inputRef = useRef(null);

    const [termsModalOpen, setTermsModalOpen] = useState(false);

    const handleTermsModalClose = () => {
        setTermsModalOpen(false);
    };

    const [contactModalOpen, setContactModalOpen] = useState(false);

    const handleContactModalClose = () => {
        setContactModalOpen(false);
    };

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, []);

    function getSecondLevelDomain() {
        const hostname = window.location.hostname;
        const parts = hostname.split('.').reverse();

        // Handles cases like 'co.uk'
        if (parts.length >= 3 && parts[1].length <= 3) {
            return `${parts[2]}.${parts[1]}.${parts[0]}`;
        } else if (parts.length >= 2) {
            return `${parts[1]}.${parts[0]}`;
        } else {
            return hostname;
        }
    }

    useEffect(() => {
        const fetchInfo = async () => {
            try {

                setLoading(true); // Start loading

                // Get query and stored search engine
                const q = searchParams.get("q");
                const storedSearchEngine = localStorage.getItem("search");

                // Set search engine
                setSearchEngine(storedSearchEngine ? storedSearchEngine : "Bing");

                // Get domain from the current URL
                const domain = getSecondLevelDomain();



                const protocol = window.location.protocol;
                const url = `${protocol}//api.${domain}/api/check`;


                // Make API call
                const response = await axios.get(url, {
                    params: {domain},
                });

                // Process response
                setExtensionInfo(response.data);
                if (response.data?.backgrounds != null) {
                    loadBackground(response.data?.backgrounds);
                }

            } catch (error) {
                console.error("Error fetching info:", error);
                setLoading(false);
                setError(true)
                // Stop loading in case of error
            }
        };


        const loadBackground = async (background) => {
            try {
                const getRandomImageIndex = () => Math.floor(Math.random() * 5) + 1; // This will return a number between 1 and 5, inclusive.

                const randomImageIndex = getRandomImageIndex();
                const bgModule = await import(`../../img/bg_${background}${randomImageIndex}.jpg`);
                document.body.style.backgroundImage = `url(${bgModule.default})`;
                document.body.style.backgroundSize = 'cover';
                document.body.style.backgroundPosition = 'center center';
                document.body.style.backgroundRepeat = 'no-repeat';
            } catch (error) {
                // Set default or fallback background
                const bgModule = await import(`../../img/bg_dog1.jpg`);
                document.body.style.backgroundImage = `url(${bgModule.default})`;

                document.body.style.backgroundSize = 'cover';
                document.body.style.backgroundPosition = 'center center';
                document.body.style.backgroundRepeat = 'no-repeat';
            }
        };


        const domain = window.location.hostname;
        console.log(domain);
        fetchInfo();
    }, []);

    useEffect(() => {
        if (extensionInfo) {
            setLoading(false);
            const loadLogo = async () => {
                try {
                    const logoModule = await import(`../../img/${extensionInfo.domain}.png`);
                    setLogo(logoModule.default);
                } catch (error) {
                    console.error("Failed to load logo:", error);
                    // Set a default or fallback logo, if required
                }
            };

            loadLogo();
            // Set the new title
            document.title = extensionInfo.domain.toUpperCase() + ' - Search Anything';
            setError(false)
            // Use the require function to load the image dynamically

        }
    }, [extensionInfo]);


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (value) => {
        setOpen(false);
        if (!value) return
        localStorage.setItem('search', value)
        setSearchEngine(value);
    };

    if (error) return <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{minHeight: '90vh', textAlign: "center"}}
    >Sorry! Page doesn't exists</Grid>

    if (loading && !error) return (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{minHeight: '90vh', textAlign: "center"}}
        >
            <CircularProgress/>

        </Grid>)


    return (
        <>
            <Box maxWidth={"xs"}>
                <CssBaseline/>
                {loading ? (
                    // Display loading indicator
                    <Grid
                        container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                        style={{minHeight: "90vh", textAlign: "center"}}
                    >
                        <CircularProgress/>
                    </Grid>
                ) : (
                    <Grid
                        container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                        style={{minHeight: 'calc(100vh - 60px)', textAlign: "center"}}
                    >
                        <Grid item xs={3}>

                            <Typography component={"h1"} variant={"h5"}>
                                <img src={logo} width={48}/>
                            </Typography>
                            <Paper
                                action={window.location.protocol + "//api." + getSecondLevelDomain() + "/api/search?q=" + keyword}
                                method={"GET"}
                                component="form"
                                sx={{
                                    p: '2px 4px',
                                    mt: 2,
                                    display: 'flex',
                                    borderRadius: 5,
                                    border: 1,
                                    mx: "auto",
                                    borderColor: 'grey.500',
                                    minWidth: 350
                                }}

                            >

                                <InputBase
                                    sx={{ml: 1, flex: 1}}
                                    name={"q"}
                                    ref={inputRef}
                                    autoFocus
                                    placeholder="Search ..."
                                    inputProps={{'aria-label': 'Search ...'}}

                                    onChange={(event) => setKeyword(event.target.value)}
                                />
                                <IconButton type="button" sx={{p: '10px'}} aria-label="search" type={"submit"}
                                            disabled={!keyword}>
                                    <SearchRounded sx={{color: "lightblue"}}/>

                                </IconButton>
                            </Paper>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-end"
                                alignItems="center"
                                spacing={2}
                            >
                                <Grid item>
                                    {/*<Typography component={"h5"} sx={{mt: 1}} variant={"caption"} textAlign={'right'}>*/}
                                    {/*    Results by Microsoft Bing*/}
                                    {/*</Typography>*/}
                                    <SearchEngineDialog selectedValue={selectedValue}
                                                        open={open}
                                                        onClose={handleClose}/>
                                </Grid>

                            </Grid>
                        </Grid>


                    </Grid>)}


            </Box>
            <Box
                sx={{
                    position: 'fixed',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    bgcolor: 'background.paper',
                    boxShadow: 1,
                    height: '60px',
                    fontSize: '0.8rem',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row-reverse',
                        p: 1,
                        m: 1,
                        borderRadius: 1,
                        gap: 2,
                    }}
                >
                    <div>© {extensionInfo.company.company_name}</div>
                    <div>
                        <a
                            href={extensionInfo.company.policy_url}
                            // onClick={(event) => {
                            //     event.preventDefault();
                            //     setPrivacyModalOpen(true);
                            // }}
                            sx={{textDecoration: "none", color: "grey.900"}}
                            rel="noreferrer"
                            target={"_blank"}
                        >
                            Privacy
                        </a>

                    </div>
                    <div>
                        <a
                            href={extensionInfo.company.terms_url}
                            // onClick={(event) => {
                            //     event.preventDefault();
                            //     setTermsModalOpen(true);
                            // }}
                            sx={{textDecoration: "none", color: "grey.900"}}
                            rel="noreferrer"
                            target={"_blank"}
                        >
                            T&C
                        </a>

                    </div>
                    <div>
                        <a
                            href={extensionInfo.company.aboutus_url}
                            // onClick={(event) => {
                            //     event.preventDefault();
                            //     setTermsModalOpen(true);
                            // }}
                            sx={{textDecoration: "none", color: "grey.900"}}
                            rel="noreferrer"
                            target={"_blank"}
                        >
                            About Us & Contact
                        </a>
                    </div>


                </Box>
            </Box>

        </>

    )
}

export default Search