import React from 'react';
import {Box, Typography, Button, Divider, Grid} from '@mui/material';
import {useEffect, useState} from "react";

import './content.css'
import {DoubleArrow} from "@mui/icons-material";


const AdComponent = ({query, ads, variant, test}) => {




    //if(!ads.length) return null;


    if (variant == "B" || test) {


        return (
            <Box>

                <Typography variant="subtitle2" sx={{mt: 2, mb: 1, color: '#333', fontSize: 10}}>
                    Sponsored Listings For "{query}"
                </Typography>

                {ads && ads.map((listing, index) => (


                    <Box
                        key={index}

                        className="ad"
                        data-yiid={listing["-ImpressionId"]}
                        data-appns={listing["-appNs"]}
                        data-k={listing["-k"]}
                        sx={{
                            background: "#f1f1f1",
                            margin: ".5em 0 1.25em 0",
                            padding: ".5rem",
                            borderRadius: "5px",
                            p: 2,
                        }}
                    >
                        <Grid container alignItems="center">
                            <Grid item xs={12} sm={12} sx={{}}>
                                <Typography variant="h5" sx={{
                                    color: '#212529', a: {
                                        color: '#212529',
                                        fontWeight: 'bold',
                                        textDecoration: 'underline', // optional: remove underline from link
                                    }
                                }}>
                                    <a target="_blank" rel="nofollow"
                                       href={`https${listing.ClickUrl["#content"]}`}>{listing["-title"].replace(/&#39;/g, "'").replace(/&amp;/g, '&')}</a>
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={9}>
                                <Typography variant="body2" sx={{
                                    marginBottom: '8px',
                                    a: {color: '#09246a', textDecoration: 'none'}
                                }}>
                                    <a target="_blank" rel="nofollow"
                                       href={`https${listing.ClickUrl["#content"]}`}>{listing["-siteHost"]}</a>
                                </Typography>
                                <Typography variant="body2"
                                            sx={{color: "#212529", a: {color: '#212529', textDecoration: 'none'}}}>
                                    <a target="_blank" rel="nofollow"
                                       href={`https${listing.ClickUrl["#content"]}`}>
                                        {listing["-description"] && listing["-description"].replace(/&amp;/g, '&').replace(/&#39;/g, "'")}</a>
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={3}
                            >
                                <Button
                                    variant="contained"
                                    endIcon={<DoubleArrow sx={{fontSize: 'large', transform: 'scale(1.5)'}}/>}
                                    color="primary"
                                    size={'large'}
                                    sx={{
                                        mt: 2,
                                        backgroundColor: '#09246a',
                                        fontWeight: 'bold',
                                        fontSize: '1.875rem',
                                        p: 0,
                                        '&:hover': {backgroundColor: '#04123a'}
                                    }}
                                    href={`https${listing.ClickUrl["#content"]}`}
                                    target="_blank"
                                    rel="nofollow"
                                    fullWidth={true}
                                >
                                    SEE IT
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                ))}
            </Box>)
    } else {
        return (
            <Box sx={{backgroundColor: '#f8f9fa', width: '100%'}}>
                <Typography variant="subtitle2" sx={{mt: 2, color: 'black', fontSize: 11}}>
                    Sponsored Listings For <strong>{query}</strong>
                </Typography>


                {ads && ads.map((listing, index) => (

                        <Box
                            key={index}
                            sx={{
                                padding: '8px 0', ml: '-16px', width: {
                                    xs: '100vw', // full width on small screens
                                    sm: '100vw', // full width on small screens
                                    md: '100%', // adjusted width on medium screens and above
                                    lg: '100%', // adjusted width on large screens and above
                                    xl: '100%', // adjusted width on extra large screens
                                }, backgroundColor: '#f8f9fa',
                            }}
                            className="ad"
                            data-yiid={listing["-ImpressionId"]}
                            data-appns={listing["-appNs"]}
                            data-k={listing["-k"]}

                        >

                            <Grid container alignItems="center">
                                <Grid item xs={12} sm={12} sx={{
                                    backgroundColor: '#bfd7ea', textAlign: 'center', pt: '25px',
                                    pb: '25px',
                                }}>
                                    <Typography variant="h7" sx={{
                                        color: '#09246a', textTransform: 'uppercase', fontWeight: 'bold', a: {
                                            color: '#09246a',

                                            textTransform: 'uppercase',
                                            fontWeight: 'bold',
                                            textDecoration: 'none', // optional: remove underline from link
                                        }
                                    }}>
                                        <a target="_blank" rel="nofollow"
                                           href={`https${listing.ClickUrl["#content"]}`}>{listing["-title"].replace(/&#39;/g, "'").replace(/&amp;/g, '&')}</a>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={9} sx={{p: 1}}>
                                    <Typography variant="body2" sx={{
                                        marginBottom: '8px',
                                        a: {color: '#09246a', fontWeight: 'bold', textDecoration: 'underline'}
                                    }}>
                                        <a target="_blank" rel="nofollow"
                                           href={`https${listing.ClickUrl["#content"]}`}>{listing["-siteHost"]}</a>
                                    </Typography>
                                    <Typography variant="body2" sx={{ a: {color: 'black', textDecoration: "none", cursor: "pointer"}}}>
                                        <a target="_blank" rel="nofollow"
                                           href={`https${listing.ClickUrl["#content"]}`}>{listing["-description"] && listing["-description"].replace(/&amp;/g, '&').replace(/&#39;/g, "'")}</a>
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sm={3}
                                    sx={{
                                        textAlign: {xs: 'center', sm: 'right'},

                                    }}
                                >
                                    <div className="ppcbutton">
                                        <a target="_blank" rel="nofollow" href={`https${listing.ClickUrl["#content"]}`}>
                                            <span>SEE IT</span>
                                        </a>
                                    </div>

                                </Grid>
                            </Grid>
                        </Box>
                    ))}
            </Box>
        );
    }

};

export default AdComponent;