// useVariant.js
import { useState, useEffect } from 'react';
import { AB_TESTS } from './abConfig';

const useVariant = (experimentKey) => {
    const experiment = AB_TESTS[experimentKey];
    const [variant, setVariant] = useState(null);

    useEffect(() => {
        const savedVariant = localStorage.getItem(experiment.id);
        if (savedVariant) {
            setVariant(savedVariant);
        } else {
            const newVariant = Math.random() < 0.5 ? experiment.variants.A.value : experiment.variants.B.value;
            localStorage.setItem(experiment.id, newVariant);
            setVariant(newVariant);
        }

        if (variant) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'customEvent',
                category: 'Experiment',
                action: 'Variant Assigned',
                label: variant,
            });
        }
    }, [experiment, variant]);

    return variant;
};

export default useVariant;