import './App.css';
import React from "react";

import Search from "./components/Search/Search";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Lp from "./components/lp";
import SearchResults from "./components/Search/Results";
import AmazingFact from "./components/Search/AmazingFact";
import Content from "./components/Search/adsquare/Content";
import SearchListings from "./components/Search/adsquare/Search";



function App() {



    const selectComponent = () => {


        const hostname = window.location.hostname;
        const pathname = window.location.pathname;
        const searchParams = new URLSearchParams(window.location.search);
        const query = searchParams.get('q');




        switch (hostname) {
            case 'promo-search.com':
                return <SearchResults />;
            case 'srch.amazingfact.org':
                return <AmazingFact />;
            case 'searching-pro.com':
                return <SearchResults />;
            case 'search-ground.com':
                return <Lp />;
            case 'yousrch.com':
                return <Lp />;
            default:
                return <Search />;
        }
    }


    return (
        <React.Fragment>
            <BrowserRouter>
                <Routes>

                    <Route exact path='/' element={selectComponent()}  />
                    <Route path='/post/:slug' element={<Content />} />
                    <Route exact path='/search' element={<SearchResults/>} />
                    <Route exact path='/query' element={<SearchListings />} />
                    <Route exact path='/amazingfact' element={<AmazingFact />} />
                    <Route exact path='/landing' element={window.location.hostname == "search-ground.com" ? <div style={{ padding: 20 }}>
                        <h1>404 Not Found</h1>
                    </div> :<Lp/>} />


                </Routes>
            </BrowserRouter>
        </React.Fragment>
    );
}

export default App;
