import React from "react";
import styles from "../landingpage.module.css";
import {useEffect, useState} from "react";
import axios from "axios";
import {CircularProgress, Grid} from "@mui/material";

const Lp = () => {
    const [extensionInfo, setExtensionInfo] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchInfo = async () => {
            try {
                setLoading(true); // Start loading

                // Get query and stored search engine

                // Get domain from the current URL
                const domain = window.location.hostname;
                const protocol = window.location.protocol;
                const url = `${protocol}//api.${domain}/api/check`;


                // Make API call
                const response = await axios.get(url, {
                    params: {domain},
                });

                // Process response
                setExtensionInfo(response.data);
                setLoading(false)

            } catch (error) {
                console.error("Error fetching info:", error);
                setLoading(false);
                // Stop loading in case of error
            }
        };

        fetchInfo()
    }, []);

    // Add 'root' class to body
    useEffect(() => {
        document.body.classList.add(styles.root);

        return () => {
            document.body.classList.remove(styles.root);
        };
    }, []);

    return (
        <>
            {loading ? (
                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    style={{minHeight: "90vh", textAlign: "center"}}
                >
                    <CircularProgress/>
                </Grid>
            ) : (
                <div className={styles.container}>
                    <img
                        src={"/img/"+extensionInfo.domain+".png"}
                        alt="Logo"
                        className={styles.logo}
                    />
                    <h1 className={styles.h1}>{extensionInfo?.title}</h1>
                    <div dangerouslySetInnerHTML={{ __html: extensionInfo.description }}></div>

                    <a
                        href={extensionInfo.chrome_url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={styles.installBtn}
                    >
                        Add to Chrome
                    </a>
                </div>)}
            <footer className={styles.footer}>
                <a href={extensionInfo?.company?.terms_url} target="_blank" rel="noopener noreferrer" className={styles.footerLink}>
                    Terms and Conditions
                </a>
                <a href={extensionInfo?.company?.policy_url} target="_blank" rel="noopener noreferrer" className={styles.footerLink}>
                    Privacy
                </a>

                <a href={extensionInfo?.company?.aboutus_url} target="_blank" rel="noopener noreferrer" className={styles.footerLink}>
                    About Us & Contact
                </a>
            </footer>
        </>
    );
};

export default Lp
