import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import axios from 'axios';
import {
    Container,
    Typography,
    Link,
    Box,
    AppBar,
    Toolbar,
    IconButton,
    CircularProgress
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import AdComponent from './AdComponent';
import amazinglogo from '../../../img/amazinglogo.png';
import TagManager from 'react-gtm-module';
import useVariant from './useVariant';
import { AB_TESTS } from './abConfig';

// Utility function to truncate text
const truncateText = (text, wordLimit) => {
    if (!text) return '';
    const words = text.trim().split(' ');
    return words.length <= wordLimit ? text : words.slice(0, wordLimit).join(' ') + '...';
};

const Content = () => {
    const { slug } = useParams(); // Get the slug from the URL
    const location = useLocation(); // Get the location object
    const queryParams = new URLSearchParams(location.search);
    const query = queryParams.get('q') || 'travel+insurance'; // Use default query if 'q' is not provided
    const test = queryParams.get('test');
    const [loading, setLoading] = useState(true);
    const [content, setContent] = useState(null);
    const [error, setError] = useState(null);
    const [ads, setAds] = useState([]);
    const [expanded, setExpanded] = useState(false);
    const [results, setResults] = useState(false);

    const variant = useVariant('experiment1');
    const truncatedText = truncateText(content?.body, 20);

    // Yahoo state
    const [clientID, setClientID] = useState('');
    const [impressionGUID, setImpressionGUID] = useState('');
    const [rguId, setRGUId] = useState('');
    const [searchID, setSearchID] = useState('');

    const clarityId = 'ifna6todnx'; // Clarity ID
    const sourceTag = 'adssquared_n2s_xmlb_2074_amazingfactorg13';

    // Fetch content data
    const fetchContentData = async () => {
        try {
            const domain = window.location.hostname;
            const protocol = window.location.protocol;
            const url = `${protocol}//api.${domain}/api/posts/slug/${slug}`;
            const response = await axios.get(url);
            setContent(response.data);
        } catch (err) {
            setError(err);
        } finally {
            setLoading(false);
        }
    };

    // Fetch ads data
    const fetchAdsData = async () => {
        try {
            const type = queryParams.get('type');
            const domain = window.location.hostname;
            const protocol = window.location.protocol;
            const url = `${protocol}//api.${domain}/api/searchfeed?q=${query}&type=${type}`;
            const response = await axios.get(url, {
                headers: {
                    Accept: 'application/json'
                }
            });

            // Log the response to understand its structure
            console.log('API response:', response);

            // Ensure the response has the expected structure
            if (response.data && response.data.Results && response.data.Results.ResultSet) {
                const allListings = gatherAllListings(response.data.Results.ResultSet);
                setResults(response.data);
                setAds(allListings);
            } else {
                console.error('Unexpected response structure:', response.data);
                setResults(null);
                setAds([]);
            }
        } catch (error) {
            console.error('Error fetching ads data:', error);
            setResults(null);
            setAds([]);
        }
    };

    const gatherAllListings = (data) => {
        let allListings = [];

        let resultSets = Array.isArray(data) ? data : [data];
        // Filter the ResultSet where id is 'searchResults'
        data = resultSets.filter(result => result['-id'] === 'searchResults');
        console.log(data,'filtered')

        if (Array.isArray(data)) {
            data.forEach((entry) => {
                if (Array.isArray(entry.Listing)) {
                    allListings = allListings.concat(entry.Listing);
                } else if (entry.Listing) {
                    allListings.push(entry.Listing);
                }
            });
        } else if (data.Listing) {
            allListings = Array.isArray(data.Listing) ? data.Listing : [data.Listing];
        }
        return allListings;
    };

    // Initialize pixels after the DOM is fully loaded
    const initPixels = async (data) => {
        if (!data) return;
        try {
            console.log('entra')
            setClientID(data.Results.ClientID);
            setImpressionGUID(data.Results.ImpressionGUID);
            setRGUId(data.Results.RGUId);
            setSearchID(data.Results.SearchID);
            initializeSelectTier(data.Results);
            insertHTMLBeacon(data.Results);
        } catch (error) {
            console.error('Error Init beacons', error);
        }
    };

    const insertHTMLBeacon = (data) => {
        const beaconUrl = `https://search.yahoo.com/beacon/geop/p?s=1197774733&ysid=${data.SearchID}&traffic_source=${sourceTag}`;
        const img = document.createElement('img');
        img.src = beaconUrl;
        img.alt = '';
        img.style.display = 'none';
        document.body.appendChild(img);
    };

    const initializeSelectTier = (data) => {
        console.log('entering initializeSelectTier');
        const script = document.createElement('script');
        script.textContent = `
      (function(w,d,t,x,m,l,p){w['XMLPlusSTObject']=m;w[m]=w[m]||function (){(w[m].q=w[m].q||[]).push(arguments)},w[m].l=1*new Date();l=d.createElement(t),p=d.getElementsByTagName(t)[0];l.type="text/javascript";l.async=1;l.defer=1;l.src=x;p.parentNode.insertBefore(l,p)})(window,document,'script','https://s.yimg.com/ds/scripts/selectTier.js','selectTier');selectTier('init',{source_tag:'${sourceTag}',ysid:'${data.SearchID}',cid:'${data.ClientID}',ig:'${data.ImpressionGUID}',select_tier:{clarityId:'${clarityId}',rguid:'${data.RGUId}'},test_mode:false});
    `;
        document.head.appendChild(script);
    };

    const getClickId = () => {
        const params = new URLSearchParams(window.location.search);
        return params.get('subid1');
    };

    useEffect(() => {
        const initializeData = async () => {
            await fetchAdsData();
            await fetchContentData();
        };
        initializeData();
    }, [slug]); // Dependency array now only includes `slug`

    useEffect(() => {
        const subid1 = getClickId();
        if (subid1) {
            TagManager.dataLayer({
                dataLayer: {
                    event: 'subid1Event',
                    subid1: subid1
                }
            });
        }
    }, [location.search]);

    useEffect(() => {

            if(results) {
                console.log('init pixels')
                initPixels(results);
            }

    }, [loading]);

    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <Typography variant="h6" color="error">Error: {error.message}</Typography>
            </Box>
        );
    }

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="fixed" sx={{ backgroundColor: '#fff', color: '#000' }}>
                <Toolbar>
                    <Box component="a" href="/" sx={{ flexGrow: 1 }}>
                        <img src={amazinglogo} alt="Logo" style={{ height: '37px' }} />
                    </Box>
                    <IconButton edge="end" color="inherit" aria-label="search" sx={{ mr: 2 }}>
                        <SearchIcon />
                    </IconButton>
                    <IconButton edge="end" color="inherit" aria-label="menu">
                        <MenuIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Container sx={{ mt: 9 }}>
                {ads.length > 0 && <AdComponent query={query} ads={ads.slice(0, 2)} variant={variant} test={test} content={content.body} />}
                <Box sx={{ color: '#09246a', mt: 1, textAlign: 'center', fontWeight: 'bold' }}>
                    <Typography variant="h5">{content.title}</Typography>
                </Box>
                <Box sx={{ mt: 2 }}>
                    <Typography variant="body2" gutterBottom>
                        {expanded ? content?.body : truncatedText} {!expanded && (
                        <Link
                            variant="body2"
                            sx={{ color: '#09246a', cursor: 'pointer', textDecoration: 'none' }}
                            onClick={() => setExpanded(true)}
                        >
                            Learn More
                        </Link>
                    )}
                    </Typography>
                </Box>
                {ads.length > 2 && <AdComponent query={query} ads={ads.slice(2, 4)} variant={variant} test={test} content={content.body} />}
            </Container>
        </Box>
    );
};

export default Content;